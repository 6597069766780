.welfare-activities {
  height: 100vh;
  width: 100%;
  margin: auto;
  position: relative;
}

.welfare-activities .center {
  position: relative;
  top: -5.333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.welfare-activities .center .center-form {
  width: 9.6rem;
  padding: 0.933333rem 0.266667rem;
  background: #ffffff;
  border-radius: 0.266667rem;
  box-shadow: 0 0.053333rem 0.32rem 0 rgba(0,0,0,0.1);
  text-align: center;
}

.welfare-activities .center .center-form .custom-left-icon {
  padding-left: 0.4rem;
  padding-right: 0.213333rem;
  display: flex;
  align-items: center;
}

.welfare-activities .center .center-form .custom-left-icon img {
  margin-right: 0.266667rem;
}

.welfare-activities .center .center-form .custom-left-icon .line {
  width: 0.026667rem;
  height: 0.48rem;
  background: rgba(0,0,0,0.2);
}

.welfare-activities .center .center-form .van-button {
  width: 7.466667rem;
  margin-top: 0.8rem;
}

.welfare-activities .center .label-rule {
  width: 9.6rem;
  padding: 0.266667rem 0;
}

.welfare-activities .center .label-rule .label-rule_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welfare-activities .center .label-rule .label-rule_header .inline {
  width: 2.933333rem;
  height: 0.053333rem;
  background: #ccd2d6;
}

.welfare-activities .center .label-rule .label-rule_header .inline-name {
  font-size: 0.64rem;
  font-weight: 500;
  color: #212121;
  margin: 0 0.266667rem;
}

.welfare-activities .center .label-rule .rule-title {
  margin-top: 0.266667rem;
  font-size: 0.48rem;
  font-weight: 500;
  color: #212121;
}

.welfare-activities .center .label-rule .rule-content {
  margin-top: 0.133333rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #5e5e5e;
  line-height: 0.426667rem;
}

.welfare-activities .center .label-rule .rule-content p {
  margin: 0;
  line-height: 0.426667rem;
}

.success-more {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.success-more img {
  width: 5.333333rem;
  margin-bottom: 0.533333rem;
}

.success-more .van-button {
  width: 8rem;
  margin-bottom: 0.533333rem;
}

.success-more .tip {
  font-weight: 400;
  color: #212121;
  line-height: 0.64rem;
}